import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import store from "../store/index.js";
import i18n from "../i18n";

import {
  ROLES,
  FORMAT_ROL,
  FASES
} from "../constants.js";

Vue.use(VueRouter);

import jwt_decode from "jwt-decode";
import sha1 from "crypto-js/sha1";

const routes = [
  {
    path: "/",
    name: "landing",
    component: () => import("../views/Landing.vue")
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/portal-profesor",
    name: "portalProfesor",
    meta: {
      authorize: [ROLES.PROFESOR, ROLES.SUPERPROFESOR]
    },
    component: () => import("../views/Profesor.vue")
  },
/*   {
    path: "/escritos-colectivos",
    name: "escritosColectivos",
    meta: {
      authorize: [ROLES.PROFESOR],
      fases: [FASES.ESCRITOS_COLECTIVOS]
    },
    component: () => import("../views/EstimuloCreativo.vue")
  },
  {
    path: "/escritos-colectivos/:escritoColectivoId",
    name: "estimuloDetail",
    meta: {
      authorize: [ROLES.PROFESOR],
      fases: [FASES.ESCRITOS_COLECTIVOS]
    },
    component: () => import("../views/EstimuloCreativoDetail.vue")
  }, */
  {
    path: "/gracias-por-participar",
    name: "thanksToParticipate",
    meta: {
      authorize: [ROLES.PROFESOR, ROLES.ADMINISTRADOR]
    },
    component: () => import("../views/ThanksToParticipate.vue")
  },
  {
    path: "/gracias-por-participar-certificado",
    name: "alumnoCertificado",
    meta: {
      authorize: [ROLES.ALUMNO]
    },
    component: () => import("../views/AlumnoCertificado.vue")
  },
  {
    path: "/concurso",
    name: "concurso",
    meta: {
      authorize: [ROLES.ALUMNO],
      fases: [FASES.REDACCION]
    },
    component: () => import("../views/Competition.vue")
  },
  {
    path: "/vuelve-pronto",
    name: "vuelvePronto",
    component: () => import("../views/VuelvePronto.vue")
  },
  {
    path: "/gestor",
    name: "gestor",
    meta: {
      authorize: [ROLES.ADMINISTRADOR]
    },
    component: () => import("../views/Gestor.vue")
  },
  {
    path: "/evaluador/:provincia",
    name: "evaluador",
    meta: {
      authorize: [ROLES.EVALUADOR],
      fases: [FASES.EVALUACION_1]
    },
    component: () => import("../views/Evaluador.vue")
  },
  {
    path: "/evaluador-fase-2/:provincia",
    name: "evaluadorf2",
    meta: {
      authorize: [ROLES.EVALUADOR],
      fases: [FASES.EVALUACION_2]
    },
    component: () => import("../views/EvaluadorFase2.vue")
  },
  {
    path: "/seleccion-de-provincia",
    name: "seleccion-provincia",
    meta: {
      authorize: [ROLES.EVALUADOR],
      fases: [FASES.EVALUACION_1, FASES.EVALUACION_2]
    },
    component: () => import("../views/ProvinceSelector.vue")
  },
  {
    path: "/relato/:relatoId/:usuarioId",
    name: "relato",
    meta: {
      authorize: [ROLES.EVALUADOR],
      fases: [FASES.EVALUACION_2]
    },
    component: () => import("../views/Relato.vue")
  },
  {
    path: "/otros-evaluadores/:provincia",
    name: "otros-evaluadores",
    meta: {
      authorize: [ROLES.EVALUADOR],
      fases: [FASES.EVALUACION_1]
    },
    component: () => import("../views/OtrosEvaluadores.vue")
  },
  {
    path: "/perfil-evaluador/:usuarioId/:provincia",
    name: "perfil-evaluador",
    meta: {
      authorize: [ROLES.EVALUADOR],
      fases: [FASES.EVALUACION_1]
    },
    component: () => import("../views/PerfilOtroEvaluador.vue")
  },
  {
    path: "/relato-otro/:relatoId/:usuarioId",
    name: "relato-otro",
    meta: {
      authorize: [ROLES.EVALUADOR],
      fases: [FASES.EVALUACION_1]
    },
    component: () => import("../views/RelatoOtroEvaluador.vue")
  },
/*   {
    path: "/lista-escritos-colectivos",
    name: "lista-escritos-colectivos",
    meta: {
      authorize: [ROLES.ADMINISTRADOR],
      fases: [FASES.ELECCION_ESTIMULO_CREATIVO]
    },
    component: () => import("../views/EvaluadorEscritoColectivo.vue")
  }, */
  {
    path: "/registro",
    name: "registro",
    component: () => import("../views/Registro.vue")
  },
  {
    path: "/olvidar_contrasena",
    name: "olvidar",
    component: () =>
      import("../views/ForgotPassword.vue")
  },
  {
    path: "/recuperar_contrasena/:email/:hash",
    name: "recuperar",
    component: () =>
      import("../views/RecoveryPassword.vue")
  },
  {
    path: "/activar/:email/:hash",
    name: "activar",
    component: () =>
      import("../views/Activate.vue")
  },
  {
    path: "/rankings",
    name: "ranking",
    meta: {
      authorize: [ROLES.ADMINISTRADOR],
      fases: [FASES.FINAL]
    },
    component: () => import("../views/Ranking.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

const openRoutes = ['login', 'registro', 'landing'];

router.beforeEach((to, from, next) => {
  const { authorize, fases } = to.meta;
  let currentUser = JSON.parse(localStorage.getItem('currentUser'));
  if (currentUser) {
    const decodedToken = jwt_decode(currentUser.access_token);
    currentUser.id = decodedToken.user_claims['id'];
    currentUser.rol = decodedToken.user_claims['rol'];
    store.commit("setCurrentUser", currentUser);
  }

  if (authorize) {
    if (!currentUser) {
      return next({ path: '/login' });
    } else {
      let rolUser = validateRol(currentUser);

      if (authorize.indexOf(rolUser) === -1) {
        return next({ path: '/login' });
      } else {
        if (fases) {
          store.dispatch("getFase").then(fases => {
            const fasesAct = fases.filter(obj => obj.activa);
            if (fasesAct.length > 0 && fasesAct.some(fase => fases.includes(fase))) {
              store.commit("setFasesActuales", fasesAct);
            } else {
              return next({ path: '/vuelve-pronto' });
            }
          }).catch(error => {
            return next({ path: '/vuelve-pronto' });
          });
        } else if (to.name === 'vuelve-pronto') {
          setRouteRole(currentUser, next);
        }
      }
    }
    next();
  } else {
    if (openRoutes.indexOf(to.name) !== -1) {
      setRouteRole(currentUser, next);
    } else {
      next();
    }
  }
});

function setRouteRole(currentUser, next) {
  if (currentUser) {
    if (validateRol(currentUser) === ROLES.PROFESOR) {
      next('/escritos-colectivos');
    } else if (validateRol(currentUser) === ROLES.ADMINISTRADOR) {
      

      store.dispatch("getFase").then(fases => {
        const fasesAct = fases.filter(obj => obj.activa);
        if (fasesAct.some(obj => obj.codigo === FASES.EVALUACION_1 || obj.codigo === FASES.EVALUACION_2)) {
          this.$router.push("gestor");
        } else if (fasesAct.some(obj => obj.codigo === FASES.FINAL)) {
          next('/rankings');
        }
      }).catch(error => {
        return next({ path: '/vuelve-pronto' });
      });
    } else if (validateRol(currentUser) === ROLES.ALUMNO) {
      next('/concurso');
    } else if (validateRol(currentUser) === ROLES.EVALUADOR) {
      store.dispatch("getFase").then(fases => {
        const fasesAct = fases.filter(obj => obj.activa);
        if (fasesAct.some(obj => obj.codigo === FASES.EVALUACION_1) || fasesAct.some(obj => obj.codigo === FASES.EVALUACION_2)) {
          next('/seleccion-de-provincia');
        }
      }).catch(error => {
        return next({ path: '/vuelve-pronto' });
      });
    } else {
      next();
    }
  } else {
    next();
  }
}

function validateRol(currentUser) {
  let parts = FORMAT_ROL.split("{}");
  const rolSuerProfesor = `${parts[0]}${currentUser.id}${parts[1]}${ROLES.SUPERPROFESOR}${parts[2]}`;
  const rolProfesor = `${parts[0]}${currentUser.id}${parts[1]}${ROLES.PROFESOR}${parts[2]}`;
  const rolEvaluador = `${parts[0]}${currentUser.id}${parts[1]}${ROLES.EVALUADOR}${parts[2]}`;
  const rolAlumno = `${parts[0]}${currentUser.id}${parts[1]}${ROLES.ALUMNO}${parts[2]}`;
  const rolAdmin = `${parts[0]}${currentUser.id}${parts[1]}${ROLES.ADMINISTRADOR}${parts[2]}`;
  const hashProfesor = sha1(rolProfesor).toString();
  const hashSuperProfesor = sha1(rolSuerProfesor).toString();
  const hashEvaluador = sha1(rolEvaluador).toString();
  const hashAlumno = sha1(rolAlumno).toString();
  const hashAdmin = sha1(rolAdmin).toString();
  if (hashProfesor === currentUser.rol) {
    return ROLES.PROFESOR;
  } else if (hashSuperProfesor === currentUser.rol) {
    return ROLES.SUPERPROFESOR;
  } else if (hashEvaluador === currentUser.rol) {
    return ROLES.EVALUADOR;
  } else if (hashAlumno === currentUser.rol) {
    return ROLES.ALUMNO;
  } else if (hashAdmin === currentUser.rol) {
    return ROLES.ADMINISTRADOR;
  } else {
    return null;
  }
}

export default router;
