<template>
  <div class="menu">
    <div class="single-image" v-if="['login', 'registro', 'olvidar', 'recuperar', 'activar'].indexOf($route.name) === -1">
      <img :src='"../assets/header/" + $i18n.locale + ".png"' />
    </div>

    <div class="languages-images" v-if="['login', 'registro', 'olvidar', 'recuperar', 'activar'].indexOf($route.name) > -1">
      <div class="go-landing" @click="goLanding()" v-if="['login', 'registro', 'olvidar', 'recuperar', 'activar'].indexOf($route.name) > -1">
        <font-awesome-icon class="mr-3" :icon="['fas', 'arrow-left']" />

        <span>{{ $t('HEADER.GO_LANDING') }}</span>
      </div>

      <div class="images is-flex is-justify-content-space-between">
        <div class="logo-img-concurso separator-rigth">
            <img src="../assets/header/es.png" />
        </div>
        <div class="logo-img-concurso separator-rigth">
            <img src="../assets/header/ca.png" />
        </div>
        <div class="logo-img-concurso separator-rigth">
            <img src="../assets/header/eu.png" />
        </div>
        <div class="logo-img-concurso separator-rigth">
            <img src="../assets/header/gl.png" />
        </div>
        <div class="logo-img-concurso separator-rigth">
            <img src="../assets/header/ba.png" />
        </div>
        <div class="logo-img-concurso">
            <img src="../assets/header/and.png" />
        </div>
      </div>
    </div>

    <div class="title-header" v-if="['login', 'registro', 'olvidar', 'recuperar', 'activar'].indexOf($route.name) === -1">
      <span>{{ $t('HEADER.TITLE') }}</span>
    </div>
    <div class="support  text-l">
      <span v-if="$store.state.users.currentUser !== null" class="user-id">{{ $store.state.users.currentUser.id}}</span>
      <span v-if="$store.state.users.currentUser !== null" class="logout" @click="logout()">({{$t('HEADER.LOGOUT')}})</span>
      <!-- <span>{{ $t('HEADER.SUPPORT') }}</span>
      <span class="icon">
        <font-awesome-icon :icon="['fal', 'question-circle']" />
      </span> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    logout() {
      this.$store.commit("setCurrentUser", null);
      localStorage.clear();
      this.$store.commit("setEstimuloCreativo", null);
      this.$router.push("/login");
    },
    goLanding() {
      this.$router.push("/");
    }
  }
};
</script>

<style scoped lang="scss">
@import '../styles/variables.scss';

.menu {
  height: 120px;
  width: 100%;
  background-color: $primary;

  display: flex;
  align-items: center;

  color: white;

  div {
    display: inline-block;
  }

  .single-image {
    margin-left: 20px;
    margin-right: 20px;
  }

  .languages-images{
    margin-left: auto;
    margin-right: auto;
  }

  .languages-images {
    width: 100%;
    display: flex;
    padding-left: 26px;
    padding-right: 26px;

    justify-content: space-between;
  }

  .languages-images img:first-child{
    border-left: none;
  }

  .images {
    width: 80%;
    padding-right: 100px;
  }

  .go-landing {
    cursor: pointer;
    padding-left: 16px;
    display: flex;
    align-items: center;
    width: 20%;

    span {
      width: 150px;
    }
  }

  img {
    max-height: 90px;
    width: 100px;
  }

  .support {
    position: absolute;
    right: 40px;

    .icon {
      margin-left: 5px;
      top: 5px;
      position: relative;
    }
  }

  .user-id {
    font-weight: 600;
  }

  .logout {
    cursor: pointer;
    padding-right: 10px;
  }

  .logout:hover {
    text-decoration: underline;
  }
}
@media (max-width: 1024px) {
.logo-img-concurso {
    width: 14%;
}

@media (max-width: 850px) {
  .images {
    display: none !important;
  }

  .go-landing {
    width: 100% !important;

    span {
      width: 100% !important;
    }
  }
}
}
</style>
