import axios from "axios";
import usersStore from "./users.js";

const estimulosCreativos = {
    state: {
    },
    mutations: {
    },
    actions: {
        createEstimulos({ commit, state }, { escritoColectivo }) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_API_URL}/escritos_colectivos`, escritoColectivo).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getEstimulo({ commit, state }, {idioma} ) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_API_URL}/escritos_colectivos/${usersStore.state.currentUser.id}/${idioma}`).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getEstimuloById({ commit, state }, { escritoColectivoId }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_API_URL}/escritos_colectivos/${usersStore.state.currentUser.id}/id/${escritoColectivoId}`).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getEstimulos({ commit, state }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_API_URL}/escritos_colectivos`).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getEstimulosByIdioma({ commit, state }, {idioma} ) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_API_URL}/estimulos_creativos/${idioma}`).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        downloadEscritosColectivos({ commit, state }, { idioma }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_API_URL}/download_escritos_colectivos?idioma=${idioma}`).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}

export default estimulosCreativos;