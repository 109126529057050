import axios from "axios";
import usersStore from "./users.js";

const relatos = {
    state: {
        estimuloCreativo: null
    },
    mutations: {
        setEstimuloCreativo(state, estimuloCreativo) {
            state.estimuloCreativo = estimuloCreativo;
        }
    },
    actions: {
        createRelatos({ commit, state }, relato) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_API_URL}/relatos`, relato, {timeout: 20000 }).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getRelato({ commit, state }, { idioma }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_API_URL}/relatos/${usersStore.state.currentUser.id}/${idioma}`).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        downloadDiploma({ commit, state }, { idioma, usuario_id }) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_API_URL}/download_diploma`, { idioma, usuario_id }, { responseType: 'blob' }).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getEstimuloCreativo({ commit, state }, { prueba_id }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_API_URL}/estimulo_creativo/${prueba_id}`).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}

export default relatos;