import axios from 'axios';
import router from './router/index.js';
import usersStore from "./store/users.js";
import store from "./store/index.js";

export default function setup() {
    axios.interceptors.request.use(config => {
        if (usersStore.state.currentUser) {
          config.headers['Authorization'] = `Bearer ${usersStore.state.currentUser.access_token}`;
        }
        return config;
    }, err => {
        return Promise.reject(err);
    });

    axios.interceptors.response.use(response => {
      return response;
    }, err => {
      if ([401, 403, 422].indexOf(err.response.status) !== -1) {
        localStorage.removeItem("currentUser");
        store.state.showSpinner = false;
        router.push('/login');
      } else {
        return Promise.reject(err);
      }
    });
}
