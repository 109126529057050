export const ROLES = {
    SUPERPROFESOR: "superprofesor",
    PROFESOR: "profesor",
    ALUMNO: "alumno",
    EVALUADOR: "evaluador",
    ADMINISTRADOR: "administrador",
    GESTOR: "gestor",
    SUPERADMIN: "superadmin"
};

export const FASES = {
    INSCRIPCION: "inscripcion",
    LOGIN_PREVIO: "login_previo",
    EVALUACION_1: "evaluacion_1",
    EVALUACION_2: "evaluacion_2",
    FINAL: "final",
    REDACCION: "redaccion"
};

export const FORMAT_ROL = "HdH4{}n4Ra!P0{}j@2cr";

export const COMMUNITIES = [
    {
        name: 'ANDALUCÍA',
        provinces: [
            {
                name: 'ALMERÍA'
            },
            {
                name: 'CÁDIZ'
            },
            {
                name: 'CÓRDOBA'
            },
            {
                name: 'GRANADA'
            },
            {
                name: 'HUELVA'
            },
            {
                name: 'JAÉN'
            },
            {
                name: 'MÁLAGA'
            },
            {
                name: 'SEVILLA'
            }
        ]
    },
    {
        name: 'ARAGÓN',
        provinces: [
            {
                name: 'HUESCA'
            },
            {
                name: 'TERUEL'
            },
            {
                name: 'ZARAGOZA'
            }
        ]
    },
    {
        name: 'PRINCIPADO DE ASTURIAS',
        provinces: [
            {
                name: 'ASTURIAS'
            }
        ]
    },
    {
        name: 'ILLES BALEARS',
        provinces: [
            {
                name: 'MALLORCA'
            },
            {
                name: 'MENORCA'
            },
            {
                name: 'IBIZA'
            }
        ]
    },
    {
        name: 'CANARIAS',
        provinces: [
            {
                name: 'LAS PALMAS'
            },
            {
                name: 'SANTA CRUZ DE TENERIFE'
            }
        ]
    },
    {
        name: 'CANTABRIA',
        provinces: [
            {
                name: 'CANTABRIA'
            }
        ]
    },
    {
        name: 'CASTILLA LA MANCHA',
        provinces: [
            {
                name: 'ALBACETE'
            },
            {
                name: 'CIUDAD REAL'
            },
            {
                name: 'CUENCA'
            },
            {
                name: 'GUADALAJARA'
            },
            {
                name: 'TOLEDO'
            }
        ]
    },
    {
        name: 'CASTILLA Y LEÓN',
        provinces: [
            {
                name: 'ÁVILA'
            },
            {
                name: 'BURGOS'
            },
            {
                name: 'LEÓN'
            },
            {
                name: 'PALENCIA'
            },
            {
                name: 'SALAMANCA'
            },
            {
                name: 'SEGOVIA'
            },
            {
                name: 'SORIA'
            },
            {
                name: 'VALLADOLID'
            },
            {
                name: 'ZAMORA'
            }
        ]
    },
    {
        name: 'CATALUNYA',
        provinces: [
            {
                name: 'BARCELONA'
            },
            {
                name: 'GIRONA'
            },
            {
                name: 'LLEIDA'
            },
            {
                name: 'TARRAGONA'
            }
        ]
    },
    {
        name: 'COMUNITAT VALENCIANA',
        provinces: [
            {
                name: 'ALICANTE'
            },
            {
                name: 'CASTELLÓN'
            },
            {
                name: 'VALENCIA'
            }
        ]
    },
    {
        name: 'EXTREMADURA',
        provinces: [
            {
                name: 'BADAJOZ'
            },
            {
                name: 'CÁCERES'
            }
        ]
    },
    {
        name: 'GALICIA',
        provinces: [
            {
                name: 'A CORUÑA'
            },
            {
                name: 'LUGO'
            },
            {
                name: 'OURENSE'
            },
            {
                name: 'PONTEVEDRA'
            }
        ]
    },
    {
        name: 'LA RIOJA',
        provinces: [
            {
                name: 'LA RIOJA'
            }
        ]
    },
    {
        name: 'MADRID',
        provinces: [
            {
                name: 'MADRID'
            }
        ]
    },
    {
        name: 'MURCIA',
        provinces: [
            {
                name: 'MURCIA'
            }
        ]
    },
    {
        name: 'NAVARRA',
        provinces: [
            {
                name: 'NAVARRA'
            }
        ]
    },
    {
        name: 'PAÍS VASCO',
        provinces: [
            {
                name: 'ÁLAVA'
            },
            {
                name: 'GIPUZKOA'
            },
            {
                name: 'BIZKAIA'
            }
        ]
    },
    {
        name: 'CIUDAD AUTÓNOMA DE CEUTA',
        provinces: [
            {
                name: 'CEUTA'
            }
        ]
    },
    {
        name: 'CIUDAD AUTÓNOMA DE MELILLA',
        provinces: [
            {
                name: 'MELILLA'
            }
        ]
    }
];

export const AREAS = {
    Todas: {
        provincias: [
            { value: 'ALBACETE', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'CIUDAD REAL', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'CUENCA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'GUADALAJARA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'CÁCERES', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'MADRID', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'SEGOVIA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'VALLADOLID', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'SALAMANCA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'ÁVILA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'TOLEDO', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'ALICANTE', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'BARCELONA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'CATALAN', value: 'ca' }] },
            { value: 'CASTELLÓN', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'GIRONA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'CATALAN', value: 'ca' }] },
            { value: 'IBIZA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'BALEAR', value: 'ba' }] },
            { value: 'LLEIDA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'CATALAN', value: 'ca' }] },
            { value: 'MALLORCA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'BALEAR', value: 'ba' }] },
            { value: 'MENORCA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'BALEAR', value: 'ba' }] },
            { value: 'TARRAGONA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'CATALAN', value: 'ca' }] },
            { value: 'VALENCIA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'A CORUÑA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'GALICIAN', value: 'gl' }] },
            { value: 'HUESCA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'ZARAGOZA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'TERUEL', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'ASTURIAS', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'BURGOS', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'CANTABRIA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'GIPUZKOA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'EUSKERA', value: 'eu' }] },
            { value: 'LA RIOJA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'LEÓN', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'LUGO', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'GALICIAN', value: 'gl' }] },
            { value: 'OURENSE', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'GALICIAN', value: 'gl' }] },
            { value: 'PONTEVEDRA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'GALICIAN', value: 'gl' }] },
            { value: 'NAVARRA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'EUSKERA', value: 'eu' }] },
            { value: 'PALENCIA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'BIZKAIA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'EUSKERA', value: 'eu' }] },
            { value: 'ZAMORA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'SORIA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'ÁLAVA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'EUSKERA', value: 'eu' }] },
            { value: 'BADAJOZ', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'ALMERÍA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'CÁDIZ', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'CÓRDOBA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'GRANADA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'HUELVA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'JAÉN', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'LAS PALMAS', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'MELILLA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'CEUTA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'MURCIA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'MÁLAGA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'SANTA CRUZ DE TENERIFE', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'SEVILLA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'ANDORRA', idiomas: [{ key: 'CATALAN', value: 'and' }, { key: 'SPANISH', value: 'es' }, { key: 'FRANCAIS', value: 'fr' }] }
        ],
        idiomas: [
            { key: 'SPANISH', value: 'es' },
            { key: 'CATALAN', value: 'ca' },
            { key: 'BALEAR', value: 'ba' },
            { key: 'EUSKERA', value: 'eu' },
            { key: 'GALICIAN', value: 'gl' },
            { key: 'FRANCAIS', value: 'fr' }
        ]
    },
    Centro: {
        provincias: [
            { value: 'ALBACETE', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'CIUDAD REAL', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'CUENCA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'GUADALAJARA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'CÁCERES', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'MADRID', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'SEGOVIA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'VALLADOLID', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'SALAMANCA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'ÁVILA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'TOLEDO', idiomas: [{ key: 'SPANISH', value: 'es' }] }
        ],
        idiomas: [{ key: 'SPANISH', value: 'es' }]
    },
    Este: {
        provincias: [
            { value: 'ALICANTE', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'BARCELONA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'CATALAN', value: 'ca' }] },
            { value: 'CASTELLÓN', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'GIRONA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'CATALAN', value: 'ca' }] },
            { value: 'IBIZA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'BALEAR', value: 'ba' }] },
            { value: 'LLEIDA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'CATALAN', value: 'ca' }] },
            { value: 'MALLORCA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'BALEAR', value: 'ba' }] },
            { value: 'MENORCA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'BALEAR', value: 'ba' }] },
            { value: 'TARRAGONA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'CATALAN', value: 'ca' }] },
            { value: 'VALENCIA', idiomas: [{ key: 'SPANISH', value: 'es' }] }
        ],
        idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'CATALAN', value: 'ca' }, { key: 'BALEAR', value: 'ba' }]
    },
    Norte: {
        provincias: [
            { value: 'A CORUÑA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'GALICIAN', value: 'gl' }] },
            { value: 'HUESCA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'ZARAGOZA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'TERUEL', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'ASTURIAS', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'BURGOS', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'CANTABRIA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'GIPUZKOA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'EUSKERA', value: 'eu' }] },
            { value: 'LA RIOJA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'LEÓN', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'LUGO', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'GALICIAN', value: 'gl' }] },
            { value: 'OURENSE', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'GALICIAN', value: 'gl' }] },
            { value: 'PONTEVEDRA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'GALICIAN', value: 'gl' }] },
            { value: 'NAVARRA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'EUSKERA', value: 'eu' }] },
            { value: 'PALENCIA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'BIZKAIA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'EUSKERA', value: 'eu' }] },
            { value: 'ZAMORA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'SORIA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'ÁLAVA', idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'EUSKERA', value: 'eu' }] }
        ],
        idiomas: [{ key: 'SPANISH', value: 'es' }, { key: 'EUSKERA', value: 'eu' }, { key: 'GALICIAN', value: 'gl' }]
    },
    Sur: {
        provincias: [
            { value: 'BADAJOZ', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'ALMERÍA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'CÁDIZ', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'CÓRDOBA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'GRANADA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'HUELVA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'JAÉN', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'LAS PALMAS', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'MELILLA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'CEUTA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'MURCIA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'MÁLAGA', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'SANTA CRUZ DE TENERIFE', idiomas: [{ key: 'SPANISH', value: 'es' }] },
            { value: 'SEVILLA', idiomas: [{ key: 'SPANISH', value: 'es' }] }
        ],
        idiomas: [{ key: 'SPANISH', value: 'es' }]
    },
    Andorra: {
        provincias: [
            { value: 'ANDORRA', idiomas: [{ key: 'CATALAN', value: 'and' }, { key: 'SPANISH', value: 'es' }, { key: 'FRANCAIS', value: 'fr' }] }
        ],
        idiomas: [{ key: 'CATALAN', value: 'and' }, { key: 'SPANISH', value: 'es' }, { key: 'FRANCAIS', value: 'fr' }]
    },
};

