import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Buefy from "buefy";
import "buefy/dist/buefy.css";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import VeeValidate from "vee-validate";

import setup from "./interceptors";
import 'core-js/stable';
// optional but required for transforming generator fns.
import 'regenerator-runtime/runtime'; 
setup();

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fas);
library.add(far);
library.add(fal);
library.add(fad);
library.add(fab);

import i18n from "./i18n";

Vue.config.productionTip = false;

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VeeValidate);
Vue.use(VueMaterial);
Vue.use(Buefy, {
  defaultIconComponent: "font-awesome-icon"
});


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
