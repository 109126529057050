<template>
  <div class="login">
    <div class="background">
      <div class="container">
        <div class="columns mt-2">
          <div class="column has-text-centered">
            <div class="title-login title-l white">
              <p>
                <b>{{ $t("LOGIN.WELCOME") }}</b>
              </p>
              <p>
                <b>{{ $t("LOGIN.COURSE") }}</b>
              </p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-half is-offset-one-quarter">
            <form @submit.prevent="validateBeforeSubmit">
              <div class="login-auth">
                <div class="columns">
                  <div class="column is-8 is-offset-2">
                    <b-notification
                      :active.sync="isActive"
                      aria-close-label="Close notification"
                      class="noti-register"
                      auto-close
                      :duration="5000"
                      :closable="false"
                    >
                      <font-awesome-icon :icon="['far', 'info-circle']" />
                      <span style="padding-left: .5rem;" v-if="errorMessage">{{
                        $t("LOGIN." + errorMessage)
                      }}</span>
                    </b-notification>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-8 is-offset-2">
                    <b-field :type="{ 'is-danger': errors.has('user_id') }">
                      <b-input
                        v-validate="'required'"
                        name="user_id"
                        v-model.trim="loginForm.user_id"
                        :placeholder="$t('LOGIN.CODE')"
                        custom-class="custom-input-i"
                        type="number"
                        icon-pack="fal"
                        icon="user"
                      ></b-input>
                    </b-field>
                    <span v-if="errors.has('user_id')" class="help is-danger">{{
                      $t("REGISTER.ERROR_REQUIRED", { field: $t("LOGIN.CODE") })
                    }}</span>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-8 is-offset-2">
                    <b-field :type="{ 'is-danger': errors.has('password') }">
                      <b-input
                        v-validate="'required'"
                        name="password"
                        :password-reveal="true"
                        v-model.trim="loginForm.password"
                        :placeholder="$t('LOGIN.PASSWORD')"
                        type="password"
                        custom-class="custom-input-i"
                        icon-pack="fal"
                        icon="lock-alt"
                      ></b-input>
                    </b-field>
                    <span v-if="errors.has('password')" class="help is-danger">{{
                      $t("REGISTER.ERROR_REQUIRED", {
                        field: $t("LOGIN.PASSWORD"),
                      })
                    }}</span>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-8 is-offset-2">
                    <h5 class="text-idioma-select">
                      {{ $t("LOGIN.IDIOMA_SELECT") }}
                    </h5>
                    <span
                      v-if="errors.has('language')"
                      class="help is-danger"
                      >{{ errors.first("language") }}</span
                    >
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-8 is-offset-2">
                    <b-field :type="{ 'is-danger': errors.has('language') }">
                      <b-select
                        v-validate="'required'"
                        name="language"
                        :placeholder="$t('LOGIN.LANGUAGE')"
                        v-model.trim="loginForm.language"
                        icon="globe"
                        icon-pack="fal"
                        @input="selectLanguage"
                      >
                        <option class="text-l" value="es">{{
                          $t("LOGIN.LANGUAGES.SPANISH")
                        }}</option>
                        <option class="text-l" value="ca">{{
                          $t("LOGIN.LANGUAGES.CATALAN")
                        }}</option>
                        <option class="text-l" value="and">{{
                          $t("LOGIN.LANGUAGES.ANDORRA")
                        }}</option>
                        <option class="text-l" value="eu">{{
                          $t("LOGIN.LANGUAGES.EUSKERA")
                        }}</option>
                        <option class="text-l" value="gl">{{
                          $t("LOGIN.LANGUAGES.GALICIAN")
                        }}</option>
                        <option class="text-l" value="ba">{{
                          $t("LOGIN.LANGUAGES.BALEAR")
                        }}</option>
                        <option class="text-l" value="fr">{{
                          $t("LOGIN.LANGUAGES.FRANCAIS")
                        }}</option>
                      </b-select>
                    </b-field>
                    <span
                      v-if="errors.has('language')"
                      class="help is-danger"
                      >{{ errors.first("language") }}</span
                    >
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-8 is-offset-2 column-button">
                    <button class="button btn-login">
                      <span
                        ><b>{{ $t("LOGIN.GET_IN") }}</b></span
                      >
                    </button>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-10 is-offset-1 link-to">
                    <router-link to="/registro">
                      <p
                        class="text-l text-primary"
                        v-html="$t('LOGIN.CREATE_ACCOUNT')"
                      ></p>
                    </router-link>
                  </div>
                </div>
                <hr />
                <div class="columns">
                  <div class="column is-10 is-offset-1 link-to">
                    <router-link to="/olvidar_contrasena"
                      ><a class="password link-reset-pwd gray bold text-l">{{
                        $t("LOGIN.FORGOT_PASSWORD")
                      }}</a></router-link
                    >
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jwt_decode from "jwt-decode";
import sha1 from "crypto-js/sha1";
import { ROLES, FORMAT_ROL, FASES } from "../constants.js";

export default {
  name: "login",
  components: {},
  data() {
    return {
      isActive: false,
      errorMessage : '',
      loginForm: {
        user_id: null,
        password: "",
        language: this.$i18n.locale,
      },
    };
  },
  created() {
    this.$i18n.locale = this.$store.state.language;
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const loginForm = {
            user_id: this.loginForm.user_id,
            password: this.loginForm.password,
            language: this.loginForm.language,
          };
          this.$store.commit("setShowSpinner", true);
          this.$store
            .dispatch("login", {
              loginForm,
            })
            .then((user) => {
              localStorage.setItem("currentUser", JSON.stringify(user));
              localStorage.setItem("language", this.loginForm.language);
              const decodedToken = jwt_decode(user.access_token);
              user.id = decodedToken.user_claims["id"];
              user.rol = decodedToken.user_claims["rol"];
              const role = this.validateRol(user);

              this.$store.commit("setCurrentUser", user);
              if (role === ROLES.ADMINISTRADOR) {
                this.$store.dispatch("getFase").then(fases => {
                  fases = fases.filter(obj => obj.activa);
                  if (fases.some(obj => obj.codigo == FASES.EVALUACION_1 || obj.codigo == FASES.EVALUACION_2)) {
                    this.$store.commit("setShowSpinner", false);
                    this.$router.push("gestor");
                  } else if (fases.some(obj => obj.codigo == FASES.FINAL)) {
                    this.$store.commit("setShowSpinner", false);
                    this.$router.push("rankings");
                  } else {
                    this.errorMessage = 'ERROR_LOGIN';
                    this.isActive = true;
                    this.$store.commit("setCurrentUser", null);
                    localStorage.removeItem("currentUser");
                    this.$store.commit("setShowSpinner", false);
                  }
                });
              } else if (role === ROLES.EVALUADOR) {
                this.$store.dispatch("getFase").then(fases => {
                  let fase;
                  fases = fases.filter(obj => obj.activa);
                  if (fases.some(obj => obj.codigo == FASES.EVALUACION_1)) {
                    fase = FASES.EVALUACION_1;
                  } else if (fases.some(obj => obj.codigo == FASES.EVALUACION_2)) {
                    fase = FASES.EVALUACION_2;
                  }
                  this.$store
                  .dispatch("getUsuarioEvaluacion", {
                    idioma: this.$i18n.locale,
                    fase,
                  })
                  .then((user) => {
                    this.$store.commit("setShowSpinner", false);
                    if (fase === FASES.EVALUACION_2) {
                      this.$store.commit("setFasesActuales", FASES.EVALUACION_2);
                      this.$router.push("seleccion-de-provincia");
                    } else if (fase === FASES.EVALUACION_1) {
                      this.$store.commit("setFasesActuales", FASES.EVALUACION_1);
                      this.$router.push("seleccion-de-provincia");
                    }
                    
                  })
                  .catch((error) => {
                    this.errorMessage = 'ERROR_LOGIN';
                    this.isActive = true;
                    this.$store.commit("setCurrentUser", null);
                    localStorage.removeItem("currentUser");
                    this.$store.commit("setShowSpinner", false);
                  });
                });
              } else if (role === ROLES.ALUMNO) {
                this.$store
                  .dispatch("getUserIdioma")
                  .then((user) => {
                    this.$store
                      .dispatch("getRelato", { idioma: this.$i18n.locale })
                      .then((relato) => {
                        this.$store.commit("setShowSpinner", false);
                        this.$router.push("concurso");
                        // if (!relato.finalizado) {
                        //   this.$store.commit("setShowSpinner", false);
                        //   this.$router.push("concurso");
                        // } else {
                        //   this.isActive = true;
                        //   this.$store.commit("setCurrentUser", null);
                        //   localStorage.removeItem("currentUser");
                        //   this.$store.commit("setShowSpinner", false);
                        // }
                      });
                  })
                  .catch((error) => {
                    this.errorMessage = 'ERROR_LOGIN';
                    this.isActive = true;
                    this.$store.commit("setCurrentUser", null);
                    localStorage.removeItem("currentUser");
                    this.$store.commit("setShowSpinner", false);
                  });
              } else if (role === ROLES.PROFESOR || role === ROLES.SUPERPROFESOR) {
                this.$store.commit("setShowSpinner", false);
                this.$router.push("portal-profesor");
              }
            })
            .catch((error) => {
              const codeError = error?.response?.data?.error;
              this.errorMessage = codeError ? codeError : 'ERROR_LOGIN';

              this.isActive = true;
              this.$store.commit("setShowSpinner", false);
            });
        }
      });
    },
    selectLanguage() {
      this.$i18n.locale = this.loginForm.language;
      localStorage.setItem("language", this.loginForm.language);
      this.$store.commit("setLanguage", this.loginForm.language);
    },
    validateRol(currentUser) {
      let parts = FORMAT_ROL.split("{}");
      const rolProfesor = `${parts[0]}${currentUser.id}${parts[1]}${ROLES.PROFESOR}${parts[2]}`;
      const rolSuperProfesor = `${parts[0]}${currentUser.id}${parts[1]}${ROLES.SUPERPROFESOR}${parts[2]}`;
      const rolEvaluador = `${parts[0]}${currentUser.id}${parts[1]}${ROLES.EVALUADOR}${parts[2]}`;
      const rolAlumno = `${parts[0]}${currentUser.id}${parts[1]}${ROLES.ALUMNO}${parts[2]}`;
      const rolAdmin = `${parts[0]}${currentUser.id}${parts[1]}${ROLES.ADMINISTRADOR}${parts[2]}`;
      const hashProfesor = sha1(rolProfesor).toString();
      const hashSuperProfesor = sha1(rolSuperProfesor).toString();
      const hashEvaluador = sha1(rolEvaluador).toString();
      const hashAlumno = sha1(rolAlumno).toString();
      const hashAdmin = sha1(rolAdmin).toString();
      if (hashProfesor === currentUser.rol) {
        return ROLES.PROFESOR;
      } else if (hashSuperProfesor === currentUser.rol) {
        return ROLES.SUPERPROFESOR;
      } else if (hashEvaluador === currentUser.rol) {
        return ROLES.EVALUADOR;
      } else if (hashAlumno === currentUser.rol) {
        return ROLES.ALUMNO;
      } else if (hashAdmin === currentUser.rol) {
        return ROLES.ADMINISTRADOR;
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";

.background {
  position: absolute;
  background: url("../assets/img/Fondo-registro.jpg") no-repeat center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  /* background-attachment: fixed; */
  width: 100%;
  min-height: calc(100% + 40px);
}

.background:before {
  content: " ";
  position: absolute;
  top: 0;
  z-index: 0;

  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
}

.title-login {
  color: white;
  font-size: 2rem;
  padding-top: 3rem;

  p {
    margin-bottom: 20px;
    height: auto;
  }
}

.login-auth {
  background-color: white;
  border-radius: 0.1rem;
  padding: 3.5rem 0;
  margin-bottom: 2rem;
}

.column-button {
  padding-top: 2.5rem;
  margin-bottom: 0.5rem;
}

.quadre-info {
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
  border: 1px solid #302783;
  font-size: 0.95rem;
  color: #302783;
}

.quadre-info .paragraf {
  padding-top: 1rem;
  font-size: 0.95rem;
}

.link-reset-pwd {
  color: black;
  font-size: 20px;
}

.btn-login {
  background-color: $primary;
  color: white;
  height: 60px;
  width: 100%;
  border-radius: 10px;
}

.btn-login:hover {
  color: white;
}

.link-to {
  text-align: center;
}

.text-idioma-select {
  color: $primary;
}

</style>
