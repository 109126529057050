import axios from "axios";

const fases = {
    state: {
        fasesActuales: []
    },
    mutations: {
        setFasesActuales(state, fases) {
            state.fasesActuales = fases;
        }
    },
    actions: {
        getFase({ commit, state }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_API_URL}/fase`).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
    }
}

export default fases;

