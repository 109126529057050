import axios from "axios";

const evaluadores = {
    state: {
        evaluacionSlide:  {
            index: 0,
            evaluaciones: [],
            limit: undefined,
        },
        allEvaluaciones: [],
        currentProvince: undefined,
        usuarioEvaluaciones: []
    },
    mutations: {
        setEvaluacionSlide(state, evaluacionSlide) {
            state.evaluacionSlide = evaluacionSlide;
        },
        setCurrentProvince(state, currentProvince) {
            state.currentProvince = currentProvince;
        },
        setAllEvaluaciones(state, allEvaluaciones) {
            state.allEvaluaciones = allEvaluaciones;
        },
        setUsuarioEvaluaciones(state, usuarioEvaluaciones) {
            state.usuarioEvaluaciones = usuarioEvaluaciones;
        }
    },
    actions: {
        getEvaluaciones({ commit, state, rootState }, { fase, usuario_id, provincia, idioma }) {
            return new Promise((resolve, reject) => {
                const usuarioId = usuario_id ? usuario_id : rootState.users.currentUser.id;
                axios.get(`${process.env.VUE_APP_API_URL}/evaluaciones/${usuarioId}?fase=${fase}&provincia=${provincia}&idioma=${idioma}`).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getEvaluacion({ commit, state, rootState }, { fase, relato_id, usuario_id }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_API_URL}/evaluacion/${relato_id}/${usuario_id}?fase=${fase}`).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getUsuarioEvaluacion({ commit, state, rootState }, { idioma, fase }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_API_URL}/usuario_evaluacion/${rootState.users.currentUser.id}/${idioma}?fase=${fase}`).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        evaluarRelato({ commit, state, rootState }, { relato_id, nota, posicion, fase }) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_API_URL}/editar_evaluaciones`, { relato_id, nota, posicion, usuario_id: rootState.users.currentUser.id, fase }).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        evaluarRelatos({ commit, state, rootState }, { evaluaciones }) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_API_URL}/editar_evaluaciones_posicion`, { evaluaciones }).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        finalizarEvaluaciones({ commit, state, rootState }, { fase, idioma, provincia, email }) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_API_URL}/finalizar_evaluaciones`, { usuario_id: rootState.users.currentUser.id, fase, idioma, provincia, email }).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        downloadRelatos({ commit, state, rootState }, { fase, provincia }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_API_URL}/download_relatos?usuario_id=${rootState.users.currentUser.id}&fase=${fase}&provincia=${provincia}`).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        downloadRelato({ commit, state }, { relato_id }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_API_URL}/download_relato?relato_id=${relato_id}`).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getOtrosEvaluadores({ commit, state }, { idioma, provincia, fase }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_API_URL}/otros_evaluadores?idioma=${idioma}&provincia=${provincia}&fase=${fase}`).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    }
}

export default evaluadores;

