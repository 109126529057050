<template>
  <div id="app">
    <Header v-if="$route.name !== 'landing'"/>
    <Spinner />
    <router-view />
    <Footer v-if="
      [
        'estimulo',
        'concurso',
        'gestor',
        'evaluador',
        'relato',
        'otros-evaluadores',
        'perfil-evaluador',
        'evaluador-ecrito',
      ].indexOf($route.name) > -1
    " />
  </div>
</template>

<style lang="scss">
@import "./styles/variables.scss";

@font-face {
  font-family: "Sans pro";
  src: url("./assets/fonts/SourceSansPro-Regular.ttf");
}

#app {
  font-family: Sans pro;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .button:focus {
    border-color: $primary;
    color: $color-text;
  }
}

.title-l {
  font-size: 34px;
}

.title-m {
  font-size: 24px;
}

.subtitle {
  font-size: 16px;
}

.text-l {
  font-size: 18px;
}

.title-header {
  font-size: 24px;
}

.text-m {
  font-size: 16px;
}

.bold {
  font-weight: bold !important;
}

.regular {
  font-weight: 100 !important;
}

.white {
  color: white !important;
}

.gray {
  color: $color-text !important;
}

.black {
  color: #000000 !important;
}

.text-primary {
  color: $primary;
}

.background {
  position: relative;
}

.menu .languagesImages img {
  /*Quitar los paddings que están ahora y cambiarlos por el margin*/
  margin: 12px;
}

.password {
  padding-top: 32px;
}

html,
body {
  background-color: $background;
}

.modal-background {
  background-color: rgba(133, 14, 22, 0.747) !important;
}

span.select select {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #4a4a4a !important;
  border-radius: 0 !important;
}

.custom-select span.select select {
  box-shadow: none !important;
  border: none !important;
  border-radius: 10px !important;
}

.custom-select span.icon {
  padding-left: 15px;
}

.custom-select .select:not(.is-multiple):not(.is-loading)::after {
  border-color: black !important;
}

select {
  width: 100%;
}

.select {
  width: 100%;
}

.custom-input-ni select {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #4a4a4a !important;
  border-radius: 0 !important;
  padding-left: 0 !important;
  color: #4a4a4a !important;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: $primary !important;
}

.md-input.md-input.md-select-value {
  color: #000000 !important;
}

input.custom-input-ni {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #4a4a4a !important;
  border-radius: 0 !important;
  padding-left: 0 !important;
}

input.custom-input-ni-datepicker {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #4a4a4a !important;
  border-radius: 0 !important;
  padding-left: 0 !important;
  background-color: transparent !important;
}

.custom-input-ni>input {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #4a4a4a !important;
  border-radius: 0 !important;
  padding-left: 0 !important;
  color: #4a4a4a;
}

.md-input.custom-input-ni.email {
  color: #e6007e !important;
  font-style: italic !important;
}

textarea.custom-input-ni {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #4a4a4a !important;
  border-radius: 0 !important;
  padding-left: 0 !important;
}

textarea.text-area {
  border: none !important;
  box-shadow: none !important;
  height: 400px !important;
  background-color: $background-box;
}

input.custom-input-background-gray {
  border: none !important;
  box-shadow: none !important;
  background-color: $background-box;
}

span.icon.is-right {
  color: $primary !important;
}

span.icon.is-left {
  justify-content: flex-start !important;
  color: $primary !important;
}

input.custom-input-i {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #4a4a4a !important;
  border-radius: 0 !important;
}

input.custom-input-add-student {
    border: none !important;
    border-radius: 10px !important;
    background-color: $background-box !important;
}

.taginput-container.is-focusable {
  border: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid black !important;
  border-radius: 0 !important;
  padding-left: 0 !important;
}

.taginput .taginput-container .autocomplete input {
  padding-left: 0 !important;
}

.taginput span.tag {
  background-color: $primary;
  color: white;
}

input::placeholder {
  color: #4a4a4a !important;
}

select::placeholder {
  color: #4a4a4a !important;
}

.md-select-menu {
  background-color: white;
}

.md-menu>i {
  border-bottom: 1px solid #4a4a4a;
  margin-bottom: 0;
  color: $primary;
}

a.button {
  width: 100% !important;
  color: white !important;
  background-color: $primary !important;
  border-color: $primary !important;
  border-radius: 3px !important;
}

.link-pink {
  color: #e6007e;
}

.link-pink:hover {
  color: $primary;
}

.link-purple {
  color: $primary;
}

.link-purple:hover {
  color: $primary;
}

span.check {
  border: 1px solid $primary !important;
  border-radius: 1px !important;
}

.b-radio.radio input[type=radio]+.check:before {
  background-color: $primary !important;
}

/* label.b-checkbox.checkbox.is-small {
  color: $primary !important;
}
.b-checkbox.checkbox input[type=checkbox]:checked + .check {
  background: $primary url("./assets/img/check-small.png") no-repeat center center !important;
} */

/* Tabs Applicant */

nav.tabs.is-centered {
  padding-top: 2rem;
  font-weight: 600;
  padding-left: 4.5rem;
  padding-right: 4.5rem;
}

.tabs ul {
  border-bottom-color: white !important;
}

.tabs ul li span {
  padding: 0 3rem;
}

.tabs li {
  width: 33%;
}

.tabs a {
  border-bottom-width: 2px !important;
  border-bottom-color: rgba(48, 39, 131, 0.35) !important;
  color: rgba(48, 39, 131, 0.35) !important;
}

.tabs a:hover {
  text-decoration: none !important;
}

.tabs li.is-active a {
  border-bottom-color: $primary !important;
  color: $primary !important;
}

section.tab-content {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}

.label.is-small {
  margin-bottom: 0 !important;
  font-weight: 400 !important;
  color: #4a4a4a !important;
}

.color-pink {
  color: #e6007e;
}

.pl-25 {
  padding-left: 2.5rem !important;
}

.pr-25 {
  padding-right: 2.5rem !important;
}

.upload-draggable p {
  color: #cbcbcb;
}

.upload-draggable .icon svg {
  height: 4rem;
  width: 4rem;
}

.mt_1 {
  margin-top: -1rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

/* .media-content {
  color: #e6007e !important;
} */

.loading-overlay .loading-icon:after {
  border: 2px solid $primary !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
}

.md-field .md-input[disabled].email {
  color: #e6007e !important;
}

.md-field .md-input[disabled],
.md-field .md-textarea[disabled] {
  color: #c6c6c6 !important;
}

.notification {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.noti-register .media-content {
  color: $primary !important;
}

.noti-register.P {
  background-color: #c6c2dc;
}

.noti-register.P .media-content {
  color: $primary !important;
}

.noti-register.A {
  background-color: #d8f2cf;
}

.noti-register.A .media-content {
  color: #30691c !important;
}

.noti-register.B {
  background-color: #ffe3b9;
}

.noti-register.B .media-content {
  color: #844f00 !important;
}

.noti-register.C {
  background-color: #ffd5d2;
}

.noti-register.C .media-content {
  color: #8b0900 !important;
}

.md-menu-content {
  max-width: 400px !important;
}

.is-checkradio[type="checkbox"]:hover:not([disabled])+label::before,
.is-checkradio[type="checkbox"]:hover:not([disabled])+label:before,
.is-checkradio[type="radio"]:hover:not([disabled])+label::before,
.is-checkradio[type="radio"]:hover:not([disabled])+label:before {
  border-color: $primary !important;
}

.is-checkradio[type="checkbox"].is-small+label {
  color: $primary;
}

.is-checkradio[type="checkbox"]+label {
  color: $primary;
}

.is-checkradio[type="checkbox"]:checked+label::before,
.is-checkradio[type="checkbox"]:checked+label:before,
.is-checkradio[type="radio"]:checked+label::before,
.is-checkradio[type="radio"]:checked+label:before {
  border: 1px solid $primary !important;
  border-radius: 1px !important;
}

.is-checkradio[type="checkbox"]+label::after,
.is-checkradio[type="checkbox"]+label:after {
  border-color: $primary !important;
}

.is-checkradio[type="checkbox"]+label::before,
.is-checkradio[type="checkbox"]+label:before,
.is-checkradio[type="radio"]+label::before,
.is-checkradio[type="radio"]+label:before {
  border: 1px solid $primary !important;
  border-radius: 1px !important;
}

.b-checkbox.checkbox input[type=checkbox]:checked+.check {
  background-color: $primary !important;
}

.modal-card-body span.icon.has-text-warning.is-large {
  color: $primary !important;
}

.modal-card-foot {
  border-top-color: white !important;
  background: white !important;
}

.modal-card-foot .button.is-danger {
  background-color: $primary !important;
  color: white !important;
}

.modal-card-foot .button.is-danger {
  background-color: $primary !important;
  color: white !important;
}

.modal-card-head {
  background: white !important;
  border-bottom-color: white !important;
}

.is-two-tabs .tabs li {
  width: 50%;
}

.upload-draggable {
  width: 100% !important;
}

.textarea[disabled] {
  background-color: white !important;
  border-color: white !important;
  border-bottom: 1px solid black !important;
  padding-left: 0px !important;
  color: #c6c6c6 !important;
}

.input[disabled] {
  background-color: white !important;
  /* color: #c6c6c6 !important; */
}

.textarea-comment {
  border: none !important;
  box-shadow: none !important;
  background-color: #efeef0 !important;
  border-radius: 0 !important;
}

.animation-content {
  overflow: auto;
}

@media (max-width: 768px) {
  .container-data {
    margin: auto 3%;
  }

  .container {
    padding: 16px;
  }

  .timer {
    margin: auto 120px;
  }

  .btn.button .text-m {
    font-size: 16px;
  }

  .logo-img-concurso {
    width: 14%;
  }

  .login-auth {
    padding: 3.5rem !important;
    margin: auto 40px;
  }

  .btn-finish.button .text-l {
    font-size: 18px;
  }

  .task {
    margin-top: 0 !important;
  }
}

.dropdown-item.is-active{
  background-color: $primary !important;
  color: white !important;
}

.dropdown-item{
  margin-top: 6px;
  margin-bottom: 6px;
  border-radius: 25px;
}

@media (max-width: 414px) {
  .logo-img-concurso {
    width: 25%;
  }

  .timer {
    margin: auto;
  }

  .box {
    padding: 1.5rem;
  }
}

.select-position-eval {
  span.select {
    select {
      background-color: whitesmoke !important;
      border-bottom: none !important;
      border: 1px solid $primary !important;
      border-radius: 20px !important;
      padding-left: 1.3rem !important;
    }
  }
}

.header-buttons {
  .label {
    color: white !important;
  }
}

</style>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "app",
  components: {
    Header,
    Footer,
    Spinner,
  },
  created() {
    if (localStorage.getItem("language")) {
      this.$i18n.locale = localStorage.getItem("language");
      this.$store.commit("setLanguage", localStorage.getItem("language"));
    } else {
      this.$i18n.locale = this.$store.state.language;
    }
  },
};
</script>
