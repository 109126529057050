<template>
  <footer class="footer">
  <div class="content has-text-centered">
    <div class="columns">
      <div class="column is-8 is-offset-2">
        <p>
          <a href="http://estaticos.cocacola.es/bases/fundacion/politica-privacidad.pdf" target="_blank">{{ $t("FOOTER.PRIVACY_POLICY") }}</a> - <a href="http://estaticos.cocacola.es/bases/fundacion/condiciones-uso.pdf" target="_blank">{{ $t("FOOTER.US_CONDITIONS") }}</a> - <a href="http://estaticos.cocacola.es/bases/fundacion/politica-cookies.pdf" target="_blank">{{ $t("FOOTER.COOKIES_POLICY") }}</a> - <a target="_blank">{{ $t("FOOTER.LEGAL_WARNING") }}</a> - <a href="mailto:">{{ $t("FOOTER.CONTACT") }}</a>
        </p>
        <p class="image has-text-centered">
            <img src="../assets/img/cocacola.png" />
        </p>
        <p>
          © "Coca Cola", la botella contour, la curva dinámica y el disco rojo son marcas registradas de The Coca Cola Company.
          <br>
          All right reserved
        </p>
      </div>
    </div>
  </div>
</footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
  }
};
</script>

<style scoped lang="scss">
    @import '../styles/variables.scss';

  .footer {
    background-color: $color-footer;
    /* background-color: #302783; */
    color: black;
    font-size: .8rem;
    position: absolute;
    width: 100%;
  }

  a {
    color: black;
    cursor: pointer;
    text-decoration: underline;
}

  .image img {
    max-height: 150px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
</style>
