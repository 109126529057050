import Vue from "vue";
import Vuex from "vuex";

import users from "./users.js";;
import estimulosCreativos from "./estimulos_creativos.js";
import fases from "./fases.js";
import relatos from "./relatos.js";
import evaluadores from "./evaluadores.js";
import gestores from "./gestores.js";
import encuestaSatisfaccion from "./encuestas_satisfaccion.js";
import ranking from './ranking.js';
import alumnos from './alumnos.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showSpinner: false,
    language: 'es',
    activeModalCompleted: false
  },
  mutations: {
    setLanguage(state, language) {
      state.language = language;
    },
    setShowSpinner(state, show) {
      state.showSpinner = show;
    },
    setActiveModalCompleted(state, show) {
      state.activeModalCompleted = show;
    }
  },
  actions: {

  },
  modules: {
    users,
    estimulosCreativos,
    fases,
    relatos,
    evaluadores,
    gestores,
    encuestaSatisfaccion,
    ranking,
    alumnos
  }
});