<template>
<b-loading :is-full-page="isFullPage" :active="$store.state.showSpinner" :can-cancel="false"></b-loading>
</template>

<script>
export default {
  name: "Spinner",
  props: {},
  data() {
    return {
      isLoading: false,
      isFullPage: true
    }
  }
};
</script>

<style scoped>

</style>
