import axios from "axios";

const ranking = {
  state: {

  },
  mutations: {

  },
  actions: {
    getRanking({ }, { provincia, idioma }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/ranking?idioma=${idioma}&provincia=${provincia}`).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    downloadRanking({ }, { provincia, idioma }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}/download_ranking_csv/${provincia}/${idioma}`).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    }
  }
}

export default ranking;

