var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(_vm.$route.name !== 'landing')?_c('Header'):_vm._e(),_c('Spinner'),_c('router-view'),(
    [
      'estimulo',
      'concurso',
      'gestor',
      'evaluador',
      'relato',
      'otros-evaluadores',
      'perfil-evaluador',
      'evaluador-ecrito',
    ].indexOf(_vm.$route.name) > -1
  )?_c('Footer'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }