import axios from "axios";

const gestores = {
    state: {

    },
    mutations: {

    },
    actions: {
        getEvaluacionesGestor({ commit, state }, { fase, area, provincia=undefined, idioma=undefined }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_API_URL}/evaluaciones_gestor?fase=${fase}&area=${area}` + (provincia ? ('&provincia=' + provincia) : '') + (idioma ? ('&idioma=' + idioma) : '')).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
    }
}

export default gestores;

