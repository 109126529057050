import axios from "axios";

const users = {
    state: {
        currentUser: null
    },
    mutations: {
        setCurrentUser(state, user) {
            state.currentUser = user;
        }
    },
    actions: {
        login({ commit, state }, { loginForm }) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_API_URL}/login`, loginForm).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        register({ commit, state }, { data }) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_API_URL}/register`, data).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getRegisterForm({ commit, state }) {
            return new Promise((resolve, reject) => {
              axios.get(`${process.env.VUE_APP_API_URL}/registro`).then(response => {
                resolve(response.data);
              }).catch(error => {
                reject(error);
              });
            });
        },
        getUser({ commit, state }) {
            return new Promise((resolve, reject) => {
              axios.get(`${process.env.VUE_APP_API_URL}/users/${state.currentUser.id}`).then(response => {
                resolve(response.data);
              }).catch(error => {
                reject(error);
              });
            });
        },
        getAlumnoPrueba({ commit, state }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_API_URL}/alumnos/${state.currentUser.id}`).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getUserIdioma({ commit, state, rootState }) {
            return new Promise((resolve, reject) => {
                axios.get(`${process.env.VUE_APP_API_URL}/usuarios_idioma/${state.currentUser.id}/${rootState.language}`).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        forgotPassword({ commit, state }, { form }) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_API_URL}/forgot_password`, form).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        changePassword({ commit, state }, { email, password, hash_recuperacion }) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_API_URL}/change_password`, { email, password, hash_recuperacion }).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        activateAccount({ commit, state }, { email, hash_activacion }) {
            return new Promise((resolve, reject) => {
                axios.post(`${process.env.VUE_APP_API_URL}/activate`, { email, hash_activacion }).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error);
                });
            });
        },
    }
}

export default users;

