import axios from "axios";

const encuestaSatisfaccion = {
  state: {
  },
  mutations: {
  },
  actions: {
    responderEncuesta({ }, { encuestaSatisfaccion }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}/encuestas`, encuestaSatisfaccion).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    },
    getPreguntas({}, { codigoEncuesta, idioma }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}/preguntas_encuestas?idioma=${idioma}&codigo_encuesta=${codigoEncuesta}`).then(response => {
          resolve(response.data);
        }).catch(error => {
          reject(error);
        });
      });
    }
  }
}

export default encuestaSatisfaccion;